import { useAppData } from "@/lib/hooks/use-app-data";
import { useMemo } from "react";

export const useNumberFormatter = (options?: Intl.DateTimeFormatOptions) => {
	const { user } = useAppData();

	const numberFormatter = useMemo(
		() =>
			new Intl.NumberFormat(user.locale, {
				...options,
			}),
		[user.locale, options],
	);

	const formatCurrency = (value: string | number | null | undefined) => {
		if (value === null || value === undefined) {
			return "0";
		}

		if (typeof value === "string") {
			value = Number(value);
		}

		return numberFormatter.format(value);
	};

	return { formatCurrency };
};
